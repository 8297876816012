import { Controller } from 'stimulus';
import IndexedDBWrapper from './indexed_db';
export default class extends Controller {
  static targets = ["editButton", "tableBody"]
  connect() {
    this.updateEditLink();
    this.loadConsultationData();
  }

  updateEditLink() {
    if (!this.hasEditButtonTarget) return;

    const clientId = this.getCurrentClientId();
    this.editButtonTarget.href += `?clientId=${clientId}`;
  }

  consultationHTML(consultation) {
    return `
      <tr class="my-auto table-row" data-action="click->table-link#selectRow" data-url="/consultations/offline_pages?clientId=${consultation["id"]}">
        <td></td>
        <td>${consultation["id"]}</td>
        <td></td>
        <td></td>
        <td></td>
        <td class="right-radius">
          <i class="fas fa-arrow-right"></i>
        </td>
    `
  }
  loadConsultationData() {
    if (!this.hasTableBodyTarget) return;

    const db = new IndexedDBWrapper();
    db.getConsultation(this.getCurrentClientId()).then((consultation) => {
      this.tableBodyTarget.insertAdjacentHTML("afterbegin", this.consultationHTML(consultation));
    });
  }

  getCurrentClientId() {
    const params = new URLSearchParams(window.location.search);
    return params.get('clientId');
  }

  deletePatient() {
    if (!this.hasTableBodyTarget) return;

    if (!confirm("Are you sure you want to delete this patient?")) {
      return;
    }
    const db = new IndexedDBWrapper();
    db.deleteConsultation(this.getCurrentClientId()).then(() => {
      db.deleteClient(this.getCurrentClientId()).then(() => {
        console.log("Patient deleted successfully");
        window.location.href = "/patients/offline_view";
      });
    });
  }
}
