import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {

  static targets = ["pages", "tabs", "label", "notes", "complete", "completeContainer"]

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    if (window.location.pathname.includes('offline_pages') && urlParams.get('selectedTab')) {
      this.tabsTargets.forEach((tab) => {
        if (tab.dataset.pageName === urlParams.get('selectedTab')) {
          // click the tab
          tab.click();
        }
      });
    } else {
      this.handleNextTab();
    }
  }

  handleNextTab() {
    const urlParams = new URLSearchParams(window.location.search);
    const nextTab = urlParams.get('next_tab');

    if (nextTab === 'true') {
      this.tabsTargets[1].classList.add('active');
    } else {
      this.tabsTarget.classList.add('active')
    }
    urlParams.delete('next_tab');
    const newUrl = urlParams.toString() ? `${window.location.pathname}?${urlParams}` : window.location.pathname;
    window.history.replaceState({}, '', newUrl);
  }

  getFieldController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "field");
  }

  fetchForm(event) {
    const { url, action } = event.target.dataset
    const ifFetch = event.target.dataset.notfetch ? false : true
    // only swap in a new form on a click event if url is defined
    if (url && ifFetch && !action) {
      // const fieldController = this.getFieldController();
      if (this.isNewOfflineHistory()) {
        const pageName = event.target.dataset.pageName;
        const clientId = this.getPatientFormController().uidTarget.value;
        this.getPatientFormController().handleSubmit(event, () => {
          window.location.href = `/consultations/offline_pages?clientId=${clientId}&selectedTab=${pageName}`;
        });
      }
      Rails.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        success: (data) => {
          // load the new form in the placeholder
          this.pagesTarget.innerHTML = data.page
          if (this.getFieldController()) this.getFieldController().toggleDependencies();
          const pageName = this.getSelectedTab();
          if (pageName === 'notes') {
            this.notesTarget.hidden = false
          }
          if (this.hasCompleteContainerTarget) {
            this.completeContainerTarget.hidden = !this.isLastPage();
          }
        }
      })
      this.updateCSSActive(event)
    }
  }

  updateCSSActive(event) {
    this.tabsTargets.forEach((element) => {
      element.classList.remove('active')

      if (element.dataset.url === event.target.dataset.url) {
        element.classList.add('active')
      }
    })
  }

  loadNotes(event) {
    this.pagesTarget.innerHTML = this.notesTarget.outerHTML
    this.notesTarget.hidden = false
    this.updateCSSActive(event)
  }

  getSelectedTab() {
    return this.tabsTargets.find((element) => element.classList.contains('active')).dataset.pageName;
  }

  isLastPage() {
    return this.getSelectedTab() === 'next_appointment';
  }

  isFirstPage() {
    return this.getSelectedTab() === 'history';
  }

  save() {
    toastr.success("Saved successfully");
  }

  complete(event) {
    const { url, backUrl } = event.target.dataset;
    if (!url) return;

    let payload = {
      consultation: {
        completed: true
      }
    };

    let formData = new URLSearchParams();
    for(let key in payload.consultation) {
      formData.append(`consultation[${key}]`, payload.consultation[key]);
    }
    Rails.ajax({
      type: 'PUT',
      url: url,
      data: formData.toString(),
      success: (_data) => {
        toastr.success("Consultation completed successfully");
        if (backUrl) {
          window.location = backUrl;
        }
      }
    })
  }

  goToNextTab() {
    const tabs = this.tabsTargets;
    let currentIndex = -1;

    tabs.forEach((tab, index) => {
      if (tab.classList.contains('active')) {
        currentIndex = index;
      }
    });

    if (currentIndex === -1) return; // No active tab found

    const nextIndex = currentIndex + 1;

    if (nextIndex < tabs.length) {
      tabs[nextIndex].click();
    } else {
      console.log('This is the last tab.');
    }
  }

  scrollToTopOfPage() {
    window.scrollTo(0, 0);
  }

  onSaveButtonClicked(event) {
    if (this.isLastPage()) {
      this.scrollToTopOfPage();
      return;
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary confirm-button',
        cancelButton: 'btn btn-primary cancel-button',
        container: 'dialog-container',
        popup: 'dialog-box'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      text: "Do you want to go to the next consultation page?",
      showCancelButton: true,
      confirmButtonText: 'OK',
      reverseButtons: true,
      target: '#content-container'
    }).then((result) => {
      const patientFormController = this.getPatientFormController();
      const isNextTab = result.isConfirmed;
      if (patientFormController && patientFormController.hasFormTarget && patientFormController.hasSavePatientButtonTarget && !navigator.onLine) {
        patientFormController.saveClient(event, isNextTab);
        // patientFormController.setNextTabValue(isNextTab);
        window.location.href = `/consultations/offline_pages?clientId=${patientFormController.uidTarget.value}&selectedTab=travel_history`;
      } else {
        if(isNextTab) {
          if (navigator.onLine && this.isFirstPage()) {
            patientFormController.storeOnlineData(isNextTab);
          }
          this.goToNextTab();
        } else {
          scrollToTopOfPage();
        }
      }
    })

  }

  getPatientFormController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "patient-form");
  }

  isNewOfflineHistory() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('is_offline') === 'true' && !this.getPatientFormController().isOfflineEditForm && this.getPatientFormController().isNewClient && this.tabsTargets[0].classList.contains('active');
  }
}
