import { Controller } from "stimulus";
import IndexedDBWrapper from "./indexed_db";

export default class extends Controller {
  static targets = ["updateLatestVersion"];
  connect() {
    if (!navigator.onLine) {
      this.updateLatestVersionTarget.hidden = true;
    } else {
      this.updateLatestVersionTarget.hidden = false;
    }
  }

  signOut(event) {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }

    // Delete all caches
    if ("caches" in window) {
      caches
        .keys()
        .then(function (cacheNames) {
          return Promise.all(
            cacheNames.map(function (cacheName) {
              return caches.delete(cacheName);
            })
          );
        })
        .then(function () {
          console.log("All caches have been deleted");
        });
    }
  }

  async clearAllOfflineData(event) {
    event.preventDefault();
    const db = new IndexedDBWrapper();
    if (confirm('Are you sure you want to clear all data?')) {
      await db.clearAllData();
      location.reload();
    }
  }

  async updateLatestVersion(event) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
        registration.unregister();
      }
    });
    if ('caches' in window) {
      caches.keys().then(function(cacheNames) {
        cacheNames.forEach(function(cacheName) {
          caches.delete(cacheName);
        });
      }).then(function() {
        console.log('All caches deleted.');
        // back to home page
        window.location.href = '/';
      }).catch(function(error) {
        console.error('Error deleting caches:', error);
      });
    } else {
      console.log('Cache storage is not supported in this browser.');
    }
  }
}
